body {
  font-size: 16px;
  line-height: 1.135;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  height: 100vh;
}

:focus {
  outline: none;
}
